import { BrandContext } from "@components/brand-context/brand-context";
import { BRAND_COMPONENTS } from "@utils/brand-components";
import { getConfig, isDevelopment } from "@utils/get-config";
import { getOpCoWithLocale, type OpCo, type OpCoInfo } from "@utils/get-opco-with-locale";
import { useRouter } from "next/router";
import { useContext } from "react";

// Helper that returns the correct OpCo and Locale based on the hostname
// accepts an argument to handle the server side rendering case
export const useBrand = (hostname?: string): OpCoInfo => {
  const brandContext = useContext(BrandContext);
  const router = useRouter();

  // If we have brand's information in the context then return it
  if (brandContext) return brandContext;

  const opCoWithLocale = getOpCoWithLocale({ hostname, locale: router.locale });

  const opCoTheme = getConfig("NEXT_PUBLIC_OVERRIDE_OPCO_THEME");
  if (isDevelopment() && opCoTheme)
    return {
      ...opCoWithLocale,
      components: BRAND_COMPONENTS[opCoTheme as OpCo],
    };

  return {
    ...opCoWithLocale,
    components: BRAND_COMPONENTS[opCoWithLocale.opCo],
  };
};
