import { getApmWeb } from "@lib/observability";
import { isPlatformBrowser } from "@utils/common";
import { getConfig, isEnv } from "@utils/get-config";
import { type Client as WSClient, createClient } from "graphql-ws";
import { useSession } from "next-auth/react";
import { createContext, type FC, useContext, useEffect, useState } from "react";

const getWSEndpointByEnv = (): string => {
  if (isEnv("staging")) {
    return getConfig("NEXT_PUBLIC_WS_ENDPOINT_STAGING");
  }

  const wsUrl = getConfig("NEXT_PUBLIC_WS_ENDPOINT");
  if (!wsUrl) {
    if (!isEnv("test")) {
      getApmWeb().captureError(
        new Error("production graphql endpoint not set"),
        "web-socket-client-provider",
      );
    }
  }

  return wsUrl;
};

const WSContext = createContext<WSClient | undefined>(undefined);

export const WebSocketClientProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [wsClient, setWsClient] = useState<WSClient | undefined>();
  const session = useSession();

  useEffect(() => {
    if (!isPlatformBrowser() || !session.data?.idToken) {
      setWsClient(undefined);
      return;
    }

    const config = {
      url: getWSEndpointByEnv(),
      keepAlive: 60_000, // ping server every 60 seconds,
      connectionParams: () => {
        return {
          authorization: `Bearer ${session.data?.idToken}`,
        };
      },
    };

    const client = createClient(config);
    setWsClient(client);

    return () => {
      client?.dispose();
    };
  }, [session.data?.idToken]);

  return <WSContext.Provider value={wsClient}>{children}</WSContext.Provider>;
};
export const useWS = () => {
  const context = useContext(WSContext);
  return context;
};
