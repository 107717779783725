export type configKey =
  | "IL_ENV"
  | "NEXT_PUBLIC_IL_ENV"
  | "NODE_ENV"
  | "NEXT_PUBLIC_"
  | "NEXTAUTH_URL"
  | "NEXT_PUBLIC_GRAPHQL_ENDPOINT"
  | "NEXT_PUBLIC_GRAPHQL_ENDPOINT_STAGING"
  | "NEXT_PUBLIC_WS_ENDPOINT"
  | "NEXT_PUBLIC_WS_ENDPOINT_STAGING"
  | "NEXT_PUBLIC_ENABLE_ANALYTICS_TRACKER"
  | "NEXT_PUBLIC_ENABLE_OBSERVABILITY"
  | "NEXT_PUBLIC_CMI5_HOMEPAGE"
  | "NEXT_PUBLIC_GAME_URL"
  | "NEXT_PUBLIC_CDN_LOCATION"
  | "NEXT_PUBLIC_OVERRIDE_OPCO"
  | "NEXT_PUBLIC_OVERRIDE_OPCO_THEME"
  | "NEXT_PUBLIC_CONFIGCAT_KEY_DEV"
  | "NEXT_PUBLIC_CONFIGCAT_KEY_JUNIORCLOUD"
  | "NEXT_PUBLIC_CONFIGCAT_KEY_STAGING"
  | "NEXT_PUBLIC_CONFIGCAT_KEY_LIBER"
  | "NEXT_PUBLIC_CONFIGCAT_KEY_PLANTYN"
  | "NEXT_PUBLIC_FEEDBACK_ENDPOINT"
  | "NEXT_PUBLIC_ENABLE_SERVICE_WORKER"
  | "TALLY_TOKEN_ENDPOINT"
  | "ELASTIC_LESSON_SEARCH_INDEX"
  | "ELASTIC_LESSON_SEARCH_INDEX_STAGING"
  | "NEXT_PUBLIC_DISCO_GRAPHQL_ENDPOINT_STAGING"
  | "NEXT_PUBLIC_DISCO_GRAPHQL_ENDPOINT"
  | "NEXT_PUBLIC_UMC_ENDPOINT_STAGING"
  | "NEXT_PUBLIC_UMC_ENDPOINT"
  | "NEXT_PUBLIC_TTS_SERVICE_ENDPOINT"
  | "NEXT_PUBLIC_OPEN_REPLAY_KEY"
  | "NEXT_PUBLIC_PREFERENCES_API_URL"
  | "NEXT_RUNTIME"
  | "NEXT_AUTH_URL"
  | "NEXT_PUBLIC_REACT_QUERY_DEVTOOLS_POSITION"
  | "NEXT_PUBLIC_IMAGE_VERSION"
  | "NEXT_PUBLIC_CLARITY_PROJECT_ID";

export type secretKey =
  | "IDP_SESSION_SECRET"
  | "IDP_CLIENT_SECRET"
  | "IDP_CLIENT_SECRET_NOORDHOFF"
  | "IDP_CLIENT_SECRET_LIBER"
  | "IDP_CLIENT_SECRET_PLANTYN"
  | "IDP_CLIENT_SECRET_STAGING"
  | "ELASTIC_API_KEY"
  | "ELASTIC_NODE";

// this is an ugly stupid solution, but we cannot use process.env[key] directly
// as next.js replaces every process.env.X values at build time, to leave process.env empty
// see here for more context https://stackoverflow.com/a/66626413
// this implementation will be replaced soon as we merge the k8s migration anyway
function fetchConfig(key: configKey) {
  switch (key) {
    case "IL_ENV":
      return process.env.IL_ENV;

    case "NEXT_PUBLIC_IL_ENV":
      return process.env.NEXT_PUBLIC_IL_ENV;

    case "NODE_ENV":
      return process.env.NODE_ENV;

    case "NEXT_PUBLIC_":
      return process.env.NEXT_PUBLIC_;

    case "NEXTAUTH_URL":
      return process.env.NEXTAUTH_URL;

    case "NEXT_PUBLIC_GRAPHQL_ENDPOINT":
      return process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT;

    case "NEXT_PUBLIC_GRAPHQL_ENDPOINT_STAGING":
      return process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_STAGING;

    case "NEXT_PUBLIC_WS_ENDPOINT":
      return process.env.NEXT_PUBLIC_WS_ENDPOINT;

    case "NEXT_PUBLIC_WS_ENDPOINT_STAGING":
      return process.env.NEXT_PUBLIC_WS_ENDPOINT_STAGING;

    case "NEXT_PUBLIC_ENABLE_ANALYTICS_TRACKER":
      return process.env.NEXT_PUBLIC_ENABLE_ANALYTICS_TRACKER;

    case "NEXT_PUBLIC_ENABLE_OBSERVABILITY":
      return process.env.NEXT_PUBLIC_ENABLE_OBSERVABILITY;

    case "NEXT_PUBLIC_CMI5_HOMEPAGE":
      return process.env.NEXT_PUBLIC_CMI5_HOMEPAGE;

    case "NEXT_PUBLIC_GAME_URL":
      return process.env.NEXT_PUBLIC_GAME_URL;

    case "NEXT_PUBLIC_CDN_LOCATION":
      return process.env.NEXT_PUBLIC_CDN_LOCATION;

    case "NEXT_PUBLIC_OVERRIDE_OPCO":
      return process.env.NEXT_PUBLIC_OVERRIDE_OPCO;

    case "NEXT_PUBLIC_OVERRIDE_OPCO_THEME":
      return process.env.NEXT_PUBLIC_OVERRIDE_OPCO_THEME;

    case "NEXT_PUBLIC_CONFIGCAT_KEY_DEV":
      return process.env.NEXT_PUBLIC_CONFIGCAT_KEY_DEV;

    case "NEXT_PUBLIC_CONFIGCAT_KEY_JUNIORCLOUD":
      return process.env.NEXT_PUBLIC_CONFIGCAT_KEY_JUNIORCLOUD;

    case "NEXT_PUBLIC_CONFIGCAT_KEY_STAGING":
      return process.env.NEXT_PUBLIC_CONFIGCAT_KEY_STAGING;

    case "NEXT_PUBLIC_CONFIGCAT_KEY_LIBER":
      return process.env.NEXT_PUBLIC_CONFIGCAT_KEY_LIBER;

    case "NEXT_PUBLIC_CONFIGCAT_KEY_PLANTYN":
      return process.env.NEXT_PUBLIC_CONFIGCAT_KEY_PLANTYN;

    case "NEXT_PUBLIC_FEEDBACK_ENDPOINT":
      return process.env.NEXT_PUBLIC_FEEDBACK_ENDPOINT;

    case "NEXT_PUBLIC_ENABLE_SERVICE_WORKER":
      return process.env.NEXT_PUBLIC_ENABLE_SERVICE_WORKER;

    case "TALLY_TOKEN_ENDPOINT":
      return process.env.TALLY_TOKEN_ENDPOINT;

    case "ELASTIC_LESSON_SEARCH_INDEX":
      return process.env.ELASTIC_LESSON_SEARCH_INDEX;

    case "ELASTIC_LESSON_SEARCH_INDEX_STAGING":
      return process.env.ELASTIC_LESSON_SEARCH_INDEX_STAGING;

    case "NEXT_PUBLIC_DISCO_GRAPHQL_ENDPOINT_STAGING":
      return process.env.NEXT_PUBLIC_DISCO_GRAPHQL_ENDPOINT_STAGING;

    case "NEXT_PUBLIC_DISCO_GRAPHQL_ENDPOINT":
      return process.env.NEXT_PUBLIC_DISCO_GRAPHQL_ENDPOINT;

    case "NEXT_PUBLIC_UMC_ENDPOINT_STAGING":
      return process.env.NEXT_PUBLIC_UMC_ENDPOINT_STAGING;

    case "NEXT_PUBLIC_UMC_ENDPOINT":
      return process.env.NEXT_PUBLIC_UMC_ENDPOINT;

    case "NEXT_PUBLIC_TTS_SERVICE_ENDPOINT":
      return process.env.NEXT_PUBLIC_TTS_SERVICE_ENDPOINT;

    case "NEXT_PUBLIC_OPEN_REPLAY_KEY":
      return process.env.NEXT_PUBLIC_OPEN_REPLAY_KEY;

    case "NEXT_PUBLIC_PREFERENCES_API_URL":
      return process.env.NEXT_PUBLIC_PREFERENCES_API_URL;

    case "NEXT_RUNTIME":
      return process.env.NEXT_RUNTIME;

    case "NEXT_AUTH_URL":
      return process.env.NEXT_AUTH_URL;

    case "NEXT_PUBLIC_REACT_QUERY_DEVTOOLS_POSITION":
      return process.env.NEXT_PUBLIC_REACT_QUERY_DEVTOOLS_POSITION;

    case "NEXT_PUBLIC_CLARITY_PROJECT_ID":
      return process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID;

    case "NEXT_PUBLIC_IMAGE_VERSION":
      return process.env.NEXT_PUBLIC_IMAGE_VERSION;

    default:
      console.warn("unsupported config key found!: ", key);
      return undefined;
  }
}

function fetchSecret(key: secretKey) {
  switch (key) {
    case "IDP_SESSION_SECRET":
      return process.env.IDP_SESSION_SECRET;

    case "IDP_CLIENT_SECRET":
    case "IDP_CLIENT_SECRET_NOORDHOFF":
      return process.env.IDP_CLIENT_SECRET;

    case "IDP_CLIENT_SECRET_LIBER":
      return process.env.IDP_CLIENT_SECRET_LIBER;

    case "IDP_CLIENT_SECRET_PLANTYN":
      return process.env.IDP_CLIENT_SECRET_PLANTYN;

    case "IDP_CLIENT_SECRET_STAGING":
      return process.env.IDP_CLIENT_SECRET_STAGING;

    case "ELASTIC_API_KEY":
      return process.env.ELASTIC_API_KEY;

    case "ELASTIC_NODE":
      return process.env.ELASTIC_NODE;

    default:
      console.warn("unsupported secret key found!: ", key);
      return undefined;
  }
}

export function getConfig(key: configKey, defaultValue = ""): string {
  const value = fetchConfig(key);
  return value ?? defaultValue;
}

export function getSecret(key: secretKey): string {
  const value = fetchSecret(key);
  return value ?? "";
}

export function isConfig(key: configKey, value: string): boolean {
  return getConfig(key)?.toLowerCase() === value?.toLowerCase();
}

export function isEnabled(key: configKey): boolean {
  return isConfig(key, "true");
}

export function isEnv(value: string) {
  return (
    isConfig("NEXT_PUBLIC_IL_ENV", value) ||
    isConfig("IL_ENV", value) ||
    isConfig("NODE_ENV", value)
  );
}

export function getEnv() {
  return getConfig("NEXT_PUBLIC_IL_ENV") || getConfig("IL_ENV") || getConfig("NODE_ENV");
}

// useful as both values are used interchangeably
export function isDevelopment() {
  return isEnv("development") || isEnv("local");
}
