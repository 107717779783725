import { BrandContext } from "@components/brand-context/brand-context";
import { Loading } from "@components/loading";
import { CacheProvider } from "@emotion/react";
import {
  CssBaseline,
  GlobalStyles,
  type Theme,
  ThemeProvider,
} from "@infinitaslearning/pixel-design-system";
import { getAnalyticsTracker, initializeAnalyticsTracker } from "@lib/analytics-tracker";
import { queryClient } from "@lib/react-query";
import { setLocalSession } from "@lib/session-store";
import { LoggedOutView } from "@components/logged-out-view";
import type { AppPropsWithLayout } from "@pages/types";
import createEmotionCache from "@styles/createEmotionCache";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getEnvironment, isPlatformBrowser } from "@utils/common";
import { getOpCoWithLocale } from "@utils/get-opco-with-locale";
import {
  INIT_MESSAGE_TYPE,
  REFRESH_TOKEN_MESSAGE_TYPE,
  SAFE_TARGET_ORIGINS,
} from "@utils/scoodleplay/iframe-communication";
import { useBrand } from "@utils/use-brand";
import useServiceWorker from "@utils/use-service-worker";
import { SessionProvider, useSession } from "next-auth/react";
import { appWithTranslation, useTranslation } from "next-i18next";
import type { AppContext } from "next/app";
import App from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import GlobalStyle from "src/styles/global-styling/global.styles";
import { getPixelBrandTheme } from "src/styles/theme";
import { initObservabilityWeb } from "@lib/observability";
import useGlobalErrorHandler from "@utils/use-global-error-handler";
import { getGlobalErrorTitle } from "@utils/get-global-error-title";
import { PlatformError } from "@components/platform-error/platform-error";
import { isIframe } from "@utils/is-iframe";
import dynamic from "next/dynamic";

const DynamicLoggedInView = dynamic(
  () => import("@components/logged-in-view").then((mod) => ({ default: mod.LoggedInView })),
  { ssr: false },
);
import { HomePlusReloadCTA } from "@components/platform-error/platform-error-ctas";
import { isEnv } from "@utils/get-config";

const environment = getEnvironment();
// we want to setup the observability module as soon as possible
initObservabilityWeb(environment);

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      body: {
        height: "100%",
      },
      html: {
        height: "100%",
      },
    }}
  />
);

const resetTheme = (theme: Theme): Theme => ({
  ...theme,
  components: {
    ...theme.components,
    MuiCssBaseline: {
      ...theme.components?.MuiCssBaseline,
    },
  },
});

const clientSideEmotionCache = createEmotionCache();

let hostname = undefined;
if (isPlatformBrowser()) {
  hostname = window?.location?.hostname;
}

if (isPlatformBrowser() && isEnv("test")) {
  const { opCo, locale } = getOpCoWithLocale();
  // Set HTML lang from here so that the lang attribure set in _document.page.tsx gets overwritten
  // That way the automatic translate suggestion will no longer show up
  document.documentElement.lang = locale;

  await initializeAnalyticsTracker({
    environment: isEnv("production") && hostname !== "localhost" ? "production" : "local",
    opCo,
    platform: "PEP",
    platformVariant: "pupil",
  });
}

type PagesProps = {
  Component: AppPropsWithLayout["Component"];
  pageProps: AppPropsWithLayout["pageProps"];
};

const ID_TOKEN_COOKIE_NAME = "id_token";
const SIGNED_IN_COOKIE_NAME = "signed_in";

const Pages = ({ Component, pageProps }: PagesProps) => {
  const router = useRouter();
  const [_, setCookie, removeCookie] = useCookies([ID_TOKEN_COOKIE_NAME, SIGNED_IN_COOKIE_NAME]);

  const { data, status } = useSession({
    required: true,
    onUnauthenticated: () => {
      // This is kinda of funny and very sad at the same time.
      // If we remove this `onUnauthenticated` callback, the `useSession` of next-auth will
      // make our app loop in a infinite loop of redirects (not always, for example in e2e tests).
      // So, at least until we don't find a better solution, we need to keep this callback here.
      // I hope this comment will help the next developer to understand why this is here because I lost more than 1 day
      // and almost cry because of this
    },
  });

  setLocalSession(data, status);

  useEffect(() => {
    if (data?.idToken) {
      const cookieOptions = {
        path: "/",
        expires: new Date(data?.expires as number),
        secure: true,
        sameSite: "strict",
        domain: location.hostname.split(".").reverse().splice(0, 2).reverse().join("."), // the top level domain
      } as const;
      // we need to make the idToken (jwt) available to embedded player services like linguineo/slimstampen
      setCookie(ID_TOKEN_COOKIE_NAME, data?.idToken, cookieOptions);
      setCookie(SIGNED_IN_COOKIE_NAME, true, cookieOptions);
    }
    return () => {
      removeCookie(ID_TOKEN_COOKIE_NAME);
      removeCookie(SIGNED_IN_COOKIE_NAME);
    };
  }, [data, removeCookie, setCookie]);

  useEffect(() => {
    // this is needed to send the event to the parent window (in case of PEP embedded in scoodle play)
    // in order to let them close the iframe and display the result screen in scoodle play
    if (isIframe() && data?.idToken && router.asPath !== "/scoodleplay-logout") {
      const token_pep = `Bearer ${data.idToken}`;

      try {
        // biome-ignore lint/complexity/noForEach: forEach is used to send the message to all the origins
        SAFE_TARGET_ORIGINS.forEach((safeTargetOrigin) => {
          window.top?.postMessage(
            JSON.stringify({ eventName: INIT_MESSAGE_TYPE, token_pep }),
            safeTargetOrigin,
          );
          window.top?.postMessage(
            JSON.stringify({ eventName: REFRESH_TOKEN_MESSAGE_TYPE, token_pep }),
            safeTargetOrigin,
          );
        });
      } catch {
        // swallow error, as only one origin will eventually be valid
      }
    }
  }, [data?.idToken, router.asPath]);

  // status will never be "unauthorized" because we use `required: true` on useSession
  if (status === "loading") {
    if (
      router.pathname.startsWith("/unauthenticated") ||
      router.pathname.startsWith("/unauthorized")
    ) {
      return <LoggedOutView Component={Component} pageProps={pageProps} />;
    }

    return <Loading />;
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <DynamicLoggedInView Component={Component} pageProps={pageProps} />
        {process.env.NEXT_PUBLIC_REACT_QUERY_DEVTOOLS_POSITION && (
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition={
              process.env.NEXT_PUBLIC_REACT_QUERY_DEVTOOLS_POSITION as
                | "bottom-left"
                | "bottom-right"
            }
          />
        )}
      </QueryClientProvider>
    </>
  );
};

const PEPPupil = ({
  Component,
  pageProps,
  // @ts-ignore: this is failing due to emotion using different versions of emotion cache for some reason. Though the difference is small enough to call ignore here.
  // TODO check if this ignore can be removed when emotion is updated again
  emotionCache = clientSideEmotionCache,
}: AppPropsWithLayout) => {
  const brand = useBrand(pageProps.hostname);
  const theme = getPixelBrandTheme(brand.opCo);
  const custTheme = resetTheme(theme);
  const { t: commonTranslate } = useTranslation("common", { keyPrefix: "platform" });
  const sessionRefetchInterval = 6 * 60 * 60; // 6 hours
  const scrollbarColor = custTheme.pixel.color.onSurface.variant;

  useEffect(() => {
    const tracker = getAnalyticsTracker();
    tracker?.awaitInitialized?.().then(() => tracker.listen());
  }, []);

  useServiceWorker();

  const { hasGlobalError, globalError } = useGlobalErrorHandler();
  const { locale } = getOpCoWithLocale();

  if (hasGlobalError) {
    return (
      <>
        <Head>
          <title>{getGlobalErrorTitle(locale)}</title>
        </Head>

        <ThemeProvider theme={custTheme}>
          <CssBaseline />
          {inputGlobalStyles}
          <GlobalStyle scrollbarColor={scrollbarColor} />
          <BrandContext.Provider value={brand}>
            <PlatformError error={globalError} errorCode={0} CustomCTA={<HomePlusReloadCTA />} />
          </BrandContext.Provider>
        </ThemeProvider>
      </>
    );
  }

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="description" content="PEP" />
          <link key="favicon" rel="icon" href={`/favicon-${brand.opCo}.ico`} />
          <title>{commonTranslate("name")}</title>
        </Head>
        <CookiesProvider>
          <SessionProvider
            refetchInterval={sessionRefetchInterval}
            session={pageProps.session}
            refetchOnWindowFocus={false}
          >
            <ThemeProvider theme={custTheme}>
              <CssBaseline />
              {inputGlobalStyles}
              <GlobalStyle scrollbarColor={scrollbarColor} />
              <BrandContext.Provider value={brand}>
                <Pages Component={Component} pageProps={pageProps} />
              </BrandContext.Provider>
            </ThemeProvider>
          </SessionProvider>
        </CookiesProvider>
      </CacheProvider>
    </>
  );
};

PEPPupil.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  appProps.pageProps.hostname = appContext?.ctx?.req?.headers?.host?.split(":")[0] || "";
  return { ...appProps };
};

export default appWithTranslation(PEPPupil);
