import { useState, useEffect } from "react";

// Export valid error messages from here
const VALID_ERROR_MESSAGES: string[] = [];

export const GRAPH_QL_ERROR = "Subscription GraphQL error.";
export const GRAPH_QL_CLOSE_ERROR = "Subscription close error.";
const APM_WEB_ERROR_PARTIAL = "TypeError: e.map is not a function";

/** Errors that only can be partially checked due to containing variables */
const PARTIALLY_VALID_ERROR_MESSAGES: string[] = [
  GRAPH_QL_ERROR,
  GRAPH_QL_CLOSE_ERROR,
  APM_WEB_ERROR_PARTIAL,
];

const isValidError = (error: Error | undefined) => {
  const hasValidError = VALID_ERROR_MESSAGES.includes(String(error));
  const hasPartiallyValidError = PARTIALLY_VALID_ERROR_MESSAGES.some((message) =>
    String(error?.toString()).includes(message),
  );
  return hasValidError || hasPartiallyValidError;
};

const useGlobalErrorHandler = () => {
  const [globalError, setGlobalError] = useState<Error | undefined>(undefined);
  const [hasGlobalError, setHasGlobalError] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onerror = (_message, _source, _lineno, _colno, error) => {
        setGlobalError(error);
        setHasGlobalError(!isValidError(error));
      };

      window.onunhandledrejection = (event) => {
        setGlobalError(event.reason);
        setHasGlobalError(!isValidError(event.reason));
      };
    }

    return () => {
      window.onerror = null;
      window.onunhandledrejection = null;
    };
  }, []);

  return { globalError, hasGlobalError };
};

export default useGlobalErrorHandler;
